import React, { useCallback, useRef } from 'react';

import { Product } from 'database';
import { useSearchQueryReader } from 'lib/hooks';
import { browserWindow } from 'lib/utils';

import { Item } from './components/Item';
import { ProductsListElements } from './elements';
import { Pagination } from './Pagination';

const { Container, List } = ProductsListElements;

const ELEMENTS_IN_LIST = 12;

export const ProductsList: React.FC<{ data: Product.Data[] }> = ({ data }) => {
  const [{ page }, onQueryChange] = useSearchQueryReader<{ page: string }>({ page: '0' });
  const ref = useRef(null);
  const onPageChange = useCallback(
    ({ selected }) => {
      if (selected !== Number(page)) {
        onQueryChange({ page: String(selected) });
        browserWindow().scrollTo(0, ref.current.offsetTop - (45 + 80 + 40)); // tabs height + navigation height  +padding
      }
    },
    [onQueryChange, page]
  );

  return (
    <Container ref={ref}>
      <List>
        {data.slice(ELEMENTS_IN_LIST * Number(page), ELEMENTS_IN_LIST * (Number(page) + 1)).map((product) => (
          <Item product={product} key={product.slug} />
        ))}
      </List>
      <Pagination
        initialPage={Number(page)}
        pageCount={Math.ceil(data.length / ELEMENTS_IN_LIST)}
        onPageChange={onPageChange}
      />
    </Container>
  );
};
