import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    ${theme.font(14, 'SEMIBOLD')};
    align-items: center;
    background: ${theme.color.blue[400]};
    border-radius: 20px;
    color: ${theme.color.white};
    display: flex;
    height: 40px;
    padding: ${theme.spacing.big};
  `
);

export const Tag: React.FC<{ className?: string }> = ({ children, className = '' }) => (
  <Container className={className}>{children}</Container>
);
