import React from 'react';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { Offer, Product } from 'database';
import Layout from 'layouts';
import { useSearchQueryReader } from 'lib/hooks';
import { SEO } from 'SEO';
import { OfferElements } from 'views/OfferPage/elements';

import { ProductsList } from './components/ProductsList';
import { TabsNav, Item } from './components/TabsNav/TabsNav';
import { SingleOfferPageElements } from './elements';

const { Content, Description, PageHeading } = SingleOfferPageElements;
const { Container } = OfferElements;

export const SingleOfferPage: React.FC<{ products: Product.Data[]; category: Offer.Data }> = ({
  products,
  category
}) => {
  const [{ activeTab }, onQueryChange] = useSearchQueryReader<{ activeTab: string; page: string }>({
    activeTab: 'ALL'
  });

  const { title, description } = category;

  const allPlates = [...new Set(products.reduce((acc, curr) => [...acc, curr.plate], []))].map((plate: string) => ({
    display: Product.Plate[plate],
    value: plate
  }));

  const matchingProducts = products.filter((product) => product.plate === activeTab || activeTab === 'ALL');

  const handleOnChange = (item: Item): void => {
    onQueryChange({ activeTab: item.value, page: '0' });
  };

  return (
    <Layout>
      <SEO title={title} canonicalHref={`/oferta/${category.slug}/`} />
      <Container>
        <PageHeading>{title}</PageHeading>
        <Breadcrumbs
          crumbs={[
            { display: 'Oferta', link: '/oferta/' },
            { display: title, link: `/oferta/${category.slug}/` }
          ]}
        />
        <Description>{description}</Description>
        <Content>
          <TabsNav
            items={[{ display: 'Wszystko', value: 'ALL' }, ...allPlates]}
            active={activeTab}
            onChange={handleOnChange}
          />
          <ProductsList data={matchingProducts} />
        </Content>
      </Container>
    </Layout>
  );
};
