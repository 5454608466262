import { Link } from 'gatsby';
import styled, { css, keyframes, DefaultTheme, Keyframes } from 'styled-components';

import { Paper } from 'components/Paper';
import { Tag } from 'components/Tag';

import { ProductsListElements } from '../../elements';

const { MAX_ELEMENT_WIDTH } = ProductsListElements;
const IMG_RATIO = 1.4536489854;

const Container = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    transition: box-shadow 0.2s ease;

    &:hover {
      box-shadow: ${theme.shadow.depth5};

      & img {
        filter: opacity(0.8);
        transition: filter 0.2s ease;
      }
    }
  `
);

const loadingAnimation = ({ theme }: { theme: DefaultTheme }): Keyframes => keyframes`
  0% {
    background: ${theme.color.gray[200]};
  }

  50% {
   background: ${theme.color.gray[200]}95;
  }

  100% {
   background: ${theme.color.gray[200]};
  }
`;

const ImgWrapper = styled(Link)`
  animation: ${loadingAnimation} 1s infinite;
  background: ${({ theme }) => theme.color.gray[300]};
  position: relative;
  transition: background 0.3s ease;

  &:hover {
    animation-play-state: paused;
  }
`;

// 2 elements with 20px gap between them + 20px padding for content
// 3 elements with 20px gap between them + 20px padding for content
// 3 elements with constant content width (1180px)

const Img = styled.img`
  display: block;
  margin: 0 auto;
  min-height: calc((100vw - 40px) / ${IMG_RATIO});
  transition: filter, height 0.2s ease;
  width: 100%;

  @media (min-width: ${MAX_ELEMENT_WIDTH * 2 + 60}px) {
    min-height: calc((100vw - 40px) / ${IMG_RATIO} / 2);
  }

  @media (min-width: ${MAX_ELEMENT_WIDTH * 3 + 80}px) {
    min-height: calc((100vw - 40px) / ${IMG_RATIO} / 3);
  }

  ${({ theme }) => theme.onScreen('SMALL_DESKTOP')} {
    min-height: calc(1180px / ${IMG_RATIO} / 3);
  }
`;

const Info = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: ${theme.spacing.big};
    position: relative;
  `
);

const Plate = styled(Tag)`
  position: absolute;
  top: -20px;
`;

const Name = styled.h3(
  ({ theme }) => css`
    ${theme.font(18, 'BOLD')};

    color: ${theme.color.blue[100]};
    margin-top: 20px;
  `
);

const ShortDescription = styled.h4(
  ({ theme }) => css`
    ${theme.font(14, 'SEMIBOLD')};

    color: ${theme.color.gray[200]};
    margin-bottom: ${theme.spacing.big};
  `
);

const MoreLink = styled(Link)(
  ({ theme }) => css`
    ${theme.font(14, 'BOLD')};

    color: ${theme.color.blue[300]};
    margin-top: auto;
    text-align: right;

    &:hover {
      color: ${theme.color.blue[400]};
    }
  `
);

export const ItemElements = {
  Container,
  ImgWrapper,
  Img,
  Info,
  Plate,
  Name,
  ShortDescription,
  MoreLink
};
