import { Link } from 'gatsby';
import React from 'react';

import { Product } from 'database';
import { useProductImage } from 'lib/hooks';

import { ItemElements } from './elements';

const { Container, ImgWrapper, Img, Info, Plate, Name, ShortDescription, MoreLink } = ItemElements;

export const Item: React.FC<{ product: Product.Data }> = ({ product }) => {
  const productLocation = `/oferta/${product.categories[0]}/${product.slug}/`;
  const image = useProductImage(product.slug);

  return (
    <Container key={product.slug} round={8} depth={6}>
      <ImgWrapper to={productLocation}>
        <Img src={image.fluid} alt={`Obraz produktu ${product.name}`} />
      </ImgWrapper>
      <Info>
        <Plate>{Product.Plate[product.plate]}</Plate>
        <Link to={productLocation}>
          <Name>{product.name}</Name>
        </Link>
        <ShortDescription>{product.shortDescription}</ShortDescription>
        <MoreLink to={productLocation}>Zobacz szczegóły</MoreLink>
      </Info>
    </Container>
  );
};
