import { graphql } from 'gatsby';
import React from 'react';

import { SingleOfferPage } from 'views/SingleOfferPage';

export default function CagegoryTemplate({ data }): React.ReactNode {
  const { category } = data.allSitePage.edges[0].node.context;
  const { products } = data.allSitePage.edges[0].node.context;

  return <SingleOfferPage category={category} products={products} />;
}

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            category {
              id
              title
              description
              shortDescription
              slug
              isMain
              type
            }
            products {
              slug
              categories
              name
              shortDescription
              description
              isOnMainPage
              images {
                src
              }
              factors
              plate
              technologies
            }
          }
        }
      }
    }
  }
`;
