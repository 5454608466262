import React from 'react';

import { TabsNavElements } from './elements';

const { Container, Tab } = TabsNavElements;

export interface Item {
  display: string;
  value: string;
}

interface TabsNavProps {
  items: Item[];
  active: string;
  onChange: (item: Item) => void;
}

export const TabsNav: React.FC<TabsNavProps> = ({ items, active, onChange }) => (
  <Container>
    {items.map((item) => (
      <Tab key={item.value} isActive={active === item.value} onClick={() => onChange(item)}>
        {item.display}
      </Tab>
    ))}
  </Container>
);
