import styled, { css } from 'styled-components';

const MAX_ELEMENT_WIDTH = 360;
const List = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-area: products-list;
    grid-gap: ${theme.spacing.big};
    grid-template-columns: repeat(auto-fill, minmax(${MAX_ELEMENT_WIDTH}px, 1fr));
    row-gap: ${theme.spacing.big};
    width: 100%;
  `
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductsListElements = {
  List,
  Container,
  MAX_ELEMENT_WIDTH
};
