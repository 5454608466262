import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    margin-bottom: ${theme.spacing.big};
    overflow: hidden;
    padding: 0;
  `
);

const Tab = styled.div<{ isActive: boolean }>(
  ({ theme, isActive }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      ${theme.font(14, 'BOLD')};
      border-bottom: 3px solid ${isActive ? theme.color.blue[300] : 'transparent'};
      color: ${isActive ? theme.color.blue[300] : '#000000'};
      display: block;

      max-width: 20%;
      padding: ${theme.spacing.mid};
      text-align: center;
      visibility: visible;
      width: 100%;

      &:hover {
        background: ${isActive ? 'transparent' : 'rgba(0, 0, 0, 0.05)'};
        cursor: pointer;
      }
    }
  `
);

export const TabsNavElements = {
  Container,
  Tab
};
