import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const Container = styled.nav(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      display: flex;
    }
  `
);

const Crumb = styled(Link)<{ active: boolean }>(
  ({ theme, active }) => css`
    color: ${active ? theme.color.blue[100] : theme.color.gray[200]};
    font-weight: ${active ? 600 : 400};

    &:after {
      content: '/';
      margin: 0 ${theme.spacing.small};
    }

    &:last-child:after {
      display: none;
    }

    &:hover {
      color: ${theme.color.blue[300]};
    }
  `
);

export const BreadcrumbsElements = {
  Container,
  Crumb
};
