import React from 'react';
import ReactPaginate from 'react-paginate';
import styled, { css } from 'styled-components';

import { Icon } from 'components/Icon';

interface PaginationProps {
  pageCount: number;
  onPageChange: (item: { selected: number }) => void;
  initialPage: number;
}

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: ${theme.spacing.bigger};
    width: 100%;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      grid-column: 2;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li a {
        align-items: center;
        background: ${theme.color.gray[400]};
        color: ${theme.color.gray[200]};
        cursor: pointer;
        display: flex;
        height: 48px;
        justify-content: center;
        margin: ${theme.spacing.mid} ${theme.spacing.small} 0;
        outline: none;
        padding: ${theme.spacing.mid};
        user-select: none;
        width: 48px;

        &:hover {
          opacity: 0.8;
        }

        ${theme.onScreen('PHONE')} {
          margin: ${theme.spacing.mid} ${theme.spacing.mid} 0;
        }
      }

      > .active a {
        color: ${theme.color.blue[200]};
        font-weight: 600;
      }

      > .previous,
      .next {
        a {
          border: 1px dashed ${theme.color.blue[200]};
          color: ${theme.color.blue[200]};
        }

        &.disabled a {
          border-color: ${theme.color.gray[200]};
          color: ${theme.color.gray[200]};
        }
      }

      > .disabled a {
        cursor: unset;

        &:hover {
          opacity: 1;
        }
      }
    }
  `
);

export const Pagination: React.FC<PaginationProps> = ({ pageCount, onPageChange, initialPage }) =>
  pageCount > 1 && (
    <Container>
      <ReactPaginate
        initialPage={initialPage}
        pageCount={pageCount}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        onPageChange={onPageChange}
        activeClassName="active"
        previousLabel={<Icon name="arrow-ios-back" />}
        nextLabel={<Icon name="arrow-ios-forward" />}
      />
    </Container>
  );
