import styled, { css } from 'styled-components';

import { PageHeading as PageHeadingBase } from 'components/Typography';

const Description = styled.article(
  ({ theme }) => css`
    ${theme.font(16)};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      ${theme.font(20)};
      margin-top: ${theme.spacing.big};
    }
  `
);

const PageHeading = styled(PageHeadingBase)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.small};

    ${theme.onScreen('SMALL_DESKTOP')} {
      margin-top: ${theme.spacing.huge};
    }
  `
);

const Content = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.big};

    ${theme.onScreen('SMALL_DESKTOP')} {
      margin-top: ${theme.spacing.huge};
    }
  `
);

const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.li(
  ({ theme }) => css`
    display: flex;
    font-size: 1.15rem;
    line-height: 1;
    margin-bottom: ${theme.spacing.big};
  `
);

const ListIcon = styled.div(
  ({ theme }) => css`
    align-items: center;
    background: ${theme.color.blue[300]};
    color: ${theme.color.white};
    height: 20px;
    margin-right: ${theme.spacing.mid};
    padding: calc(${theme.spacing.small} / 2);
    width: 20px;
  `
);

const P = styled.p`
  font-size: 1.2rem;
  margin-bottom: ${({ theme }) => theme.spacing.big};
`;

export const SingleOfferPageElements = {
  Description,
  PageHeading,
  Content,
  List,
  ListItem,
  ListIcon,
  P
};
